import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";
import graphrefIcon from "../../Assets/Images/refresh-icon.png";
import PieChart from "../../Components/Charts/PieChart/PieChart";
import CompHistogram from "../../Components/Charts/Histogram/CompHistogram";
import BarChart from "../../Components/Charts/BarChart";
import LineChart from "../../Components/Charts/LineChart";
import RiskStats from "../../Components/Charts/RiskStats/RiskStats";
import PyramidChart from "../../Components/Charts/PyramidChart/PyramidChart";
import { PatientsNumberContext } from "../../Contexts/totalPatientsContext";
import format from "../../Utils/formatNumber";
import formatDate from "../../Utils/formatDate";
import DiagHistogram from "../../Components/Charts/Histogram/DiagHistogram";
import Alert from '@mui/lab/Alert';
// import GeoChart from "../Charts/GeoChart";
import { AnalyticsContext } from "../../Contexts/analyticsContext";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid, IconButton } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import Modal from '@mui/material/Modal';
import { MetaDetailsContext } from "../../Contexts/metaDetailsContext";
import { PyramidContext } from "../../Contexts/uiContext";
import Sidebar from "../../Components/Sidebar/Sidebar"
import { DropdownFilterContext } from "../../Contexts/dropdownContext";
import CustomAttributeChart from "../../Components/Charts/CustomAttributeChart";
import { PatientSetContext } from "../../Contexts/patientSetContext";
import { Tooltip } from "@mui/material";
import { toCapitalize, toLowerCase, toPluralize } from "../../Utils/stringOprations";
import GridSkeletonLoader from "../../Components/SkeletonLoader/GridSkeletonLoader";
import PyramidSkeleton from "../../Components/SkeletonLoader/PyramidSkeleton";
// import { AuthContext } from "../../Contexts/authContext";

const useStyles = makeStyles((theme) => ({
  customModal: {
    width: "100%",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto"
  },
  modalContent: {
    width: "auto",
    maxWidth: "80%",
    minHeight: "calc(100vh - 60px)",
    height: "auto",
    margin: "30px auto",
    display: "flex",
    alignItems: "center"
  },
  paper: {
    position: 'relative',
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5, 4, 3),
  },
  closeButton: {
    position: 'absolute !important',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: "#000",
  },
  backdrop: {
    zIndex: 500,
    color: "#6baaed",
  },
}));


const DashboardOverview = ({reportIframe = false}) => {
  const classes = useStyles();
  const history = useNavigate();
  const {
    patients,
    hccData,
    seriesComp,
    seriesImp,
    optionsComp,
    optionsImp,
    pyramidComp,
    pyramidImp,
    pyramidDiag,
    pyramidQual,
    dataAvailable,
    fetchData,
    startStats,
    fetchCustomAttributeChartData,
    customAttributeChartLoader,
    unknownCountComp,
    unknownCountImp,
    unknownCountDiag,
    unknownCountQual,
    pmpmData,
    totalPmpmCost,
    customAttrChartData,
    histograms
  } = useContext(PatientsNumberContext);
  const { patientSetInfo, updatePatientSetInfo, fetchingPatientSet } = useContext(PatientSetContext);
  const { dispatch } = useContext(DropdownFilterContext);
  const { info, setInfo } = useContext(PyramidContext);
  const { setPageTitle } = useContext(MetaDetailsContext);
  const { event } = useContext(AnalyticsContext);
  const { org_guid } = JSON.parse(localStorage.getItem('user')).user;

  
  const [workSheetDates, setWorkSheetDates] = useState({ last_updated: "", selected_date: "" });
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Comp Histograms
  const compSmallHistogram = histograms.compHistogram.smallHistogram;
  const compLargeHistogram = histograms.compHistogram.largeHistogram;

  // Diag Histograms
  const diagSmallHistogram = histograms.diagHistogram.smallHistogram;
  const diagLargeHistogram = histograms.diagHistogram.largeHistogram;

  useEffect(() => {
    if (patientSetInfo) {
      setPageTitle(`FHS: ${toCapitalize(patientSetInfo.cohort_name)} Overview`);
      startStats();
      fetchCustomAttributeChartData();
      setWorkSheetDates({
        last_updated: patientSetInfo.last_updated,
        selected_date: patientSetInfo.selected_date
      });

      //GA - Track the currently active cohort
      event('cohort_active', { cohort_id: patientSetInfo.patient_set_id.slice(-12), cohort_name: patientSetInfo.name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientSetInfo]);
  
  const risk_stratification = () => {
    fetchData();
    fetchCustomAttributeChartData();
  }

  const redirectToPatientList = () => {
    setInfo({
      ...info,
      currentui: 'patientview',
      prevui: info.currentui,
      pyramidRiskStatus: 'none',
    });
    history({
      pathname: "/patient-list",
      state: { uititle: `All Active ${toPluralize(patientSetInfo.patient_name)}` }
    })
  };

  const redirectToPatientListWithNLPVerified = () => {
    setInfo({
      ...info,
      currentui: 'patientview',
      prevui: info.currentui,
      pyramidRiskStatus: 'none',
    })
    dispatch({ type: 'set-nlp-filter', payload: { name: "patientNLPVerifiedContributor", value: 1 } });
    history({
      pathname: "/patient-list",
      state: { uititle: `All Active ${toPluralize(patientSetInfo.patient_name)}` }
    })
  }

  const redirectToPatientListUnknown = (key) => {
    setInfo({
      ...info,
      key: key,
      currentui: "patientview",
      prevui: 'stats',
      pyramidRiskStatus: 'unknown',
    })
    history({
      pathname: "/patient-list",
      state: { uititle: `${toPluralize(patientSetInfo.patient_name)} in Unknown Risk Stratus` }
    })
  };

  const updateSelectedDate = () => {
    updatePatientSetInfo({...patientSetInfo, selected_date: workSheetDates.last_updated});
    window.location.reload();
  };

  return (
    <Sidebar pageTitle={`${toCapitalize(patientSetInfo?.cohort_name)} Overview`}>
      <section className="DashboardOuter">
        {/* <Header /> */}
        <div className="dashMidCont app-bg-color">
          <div className="container clearfix">
            <Grid container spacing={5} columns={12} mb={5}>
              {dataAvailable || fetchingPatientSet || !patientSetInfo ? (
                new Array(10).fill(0).map((_, index) => {

                  if (index === 2 || index === 3) { 
                    return <PyramidSkeleton key={index} />
                  }

                  return (
                    <GridSkeletonLoader key={index} />
                  )
                })
              ) : (<>
              {/* Total patients cohort component */}
              <Grid item lg={6} sm={12} xs={12}  md={12}>
                <div className="GraphBg widget-bg-shadow totalPatients">
                <div className="comprenhHd">Total {patientSetInfo.patient_name}</div>
                  <button className="graphRefBtn">
                    <img src={graphrefIcon} alt="" />
                  </button>
                  <div className="TotlPatient clearfix">
                    {dataAvailable ? 'Loading...' : <PieChart patients={patients} patient_name={patientSetInfo.patient_name} />}
                    <span>
                    <h5 className="mb-3">
                        View <small onClick={reportIframe ? null: () => redirectToPatientList()}>{format(patients.active_count)} active</small> {toPluralize(toLowerCase(patientSetInfo.patient_name))} of <br />
                        {format(patients.total)} all-time {toPluralize(toLowerCase(patientSetInfo.patient_name))} in this <br />
                        {patientSetInfo.cohort_name}.
                      </h5>
                      {
                        workSheetDates.last_updated === workSheetDates.selected_date ? (
                          <h5>Worksheet current as of {formatDate(workSheetDates.last_updated)}.</h5>
                        )
                          : (
                            <h5>Worksheet data as of {formatDate(workSheetDates.selected_date)}. <br />
                              (The most recent data for this {toLowerCase(patientSetInfo.cohort_name)} <br />
                              <small onClick={reportIframe ? null: () => { updateSelectedDate(); }}>
                                is from {formatDate(workSheetDates.last_updated)})
                              </small>
                              .
                            </h5>
                          )
                      }
                      <h5 className="mb-3 hyperlink" hidden={true} onClick={reportIframe ? null:() => redirectToPatientListWithNLPVerified()}>
                        <DownloadIcon fontSize="small" sx={{ marginRight: 1, color: "#3aa290"}}  />
                        {"NLP Identified and verified Contributors available for export"}
                      </h5>
                      {
                        patients.num_updates
                          ? <button className="updateBtn" onClick={reportIframe ? null : risk_stratification}>Click to Update</button>
                          : null
                      }
                    </span>
                  </div>
                </div>
              </Grid>
              {/* Comprehensive Risk Summary component */}
              <Grid item lg={6} sm={12} xs={12} md={12}>
                <div className="GraphBg widget-bg-shadow compRiskSummary">
                  <button className="graphRefBtn">
                    <img src={graphrefIcon} alt="" />
                  </button>
                  <div className="comprenhHd">Comprehensive Risk Summary</div>
                  <span className="db-subtitle">
                    Monthly Statistics
                  </span>
                  <div className="HccRisk clearfix">
                    <RiskStats hccData={hccData} />
                  </div>
                  <br />
                </div>
              </Grid>
              {/* Comprehensive Risk Stratification component */}
              <Grid item lg={6} sm={12} xs={12} md={12}>
                <div className="GraphBg widget-bg-shadow compRiskStratification">
                  <button className="graphRefBtn">
                    <img src={graphrefIcon} alt="" />
                  </button>
                  <div className="comparenhOuter">
                    <div className="comprenhHd">Comprehensive Risk Stratification</div>
                    <span className="db-subtitle">Composite Diagnostic and SDoH Score</span>
                    <div className="comprenshGraph graph-wrapper">
                      <div className="clearfix">
                        <div className="pyramidLeft">
                          <h3 className="pl-0">
                            <small 
                              style={{ color: "#3aa290", textDecoration: "underline", cursor: "pointer" }} 
                              onClick={reportIframe? null :() =>redirectToPatientList()}
                            >
                              View all {format(patients.active_count)} {toPluralize(toLowerCase(patientSetInfo.patient_name))}
                            </small>
                          </h3>
                          <h5>
                            or click pyramid for <br /> category details.
                          </h5>
                          <h5>
                            Additionally there are <br />
                            <small onClick={reportIframe ? null: () => redirectToPatientListUnknown("cs")}>
                            {unknownCountComp} {toPluralize(toLowerCase(patientSetInfo.patient_name))} with unknown risk, <br />
                            </small>
                            for a total of {format(patients.active_count)} active {toPluralize(toLowerCase(patientSetInfo.patient_name))}.
                          </h5>
                        </div>
                        <PyramidChart 
                            type="overview" 
                            pyramidValues={pyramidComp} 
                            riskKeyName="cs" 
                            reportIframe = {reportIframe}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
               {/* Diagnostic Risk Stratification component */}
               <Grid item lg={6} sm={12} xs={12} md={12}>
                <div className="GraphBg widget-bg-shadow diagRiskStratification">
                  <button className="graphRefBtn">
                    <img src={graphrefIcon} alt="" />
                  </button>
                  <div className="comparenhOuter">
                    <div className="comprenhHd">Diagnostic Risk Stratification</div>
                    <span className="db-subtitle">Risk Score Stratification Based on Diagnostic Contributors</span>
                    <div className="comprenshGraph graph-wrapper">
                      <div className="clearfix">
                        <div className="pyramidLeft">
                          <h3 className="pl-0">
                            <small 
                                style={{ color: "#3aa290", textDecoration: "underline", cursor: "pointer" }} 
                                onClick={reportIframe ? null: () =>redirectToPatientList()}
                            >
                              View all {format(patients.active_count)} {toPluralize(toLowerCase(patientSetInfo.patient_name))}</small>
                          </h3>
                          <h5>
                            or click pyramid for <br /> category details.
                          </h5>
                          <h5>
                            Additionally there are <br />
                            <small onClick={reportIframe ? null: () => redirectToPatientListUnknown("drc")}>
                            {unknownCountDiag} {toPluralize(toLowerCase(patientSetInfo.patient_name))} with unknown risk,<br />
                            </small>
                            for a total of {format(patients.active_count)} active {toPluralize(toLowerCase(patientSetInfo.patient_name))}.
                          </h5>
                        </div>
                        <PyramidChart 
                            type="overview" 
                            pyramidValues={pyramidDiag} 
                            riskKeyName="drc" 
                            reportIframe={reportIframe}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            
              {/* Comprehensive Risk Distribution component */}
              <Grid item lg={6} sm={12} xs={12} md={12}>
                <div className="GraphBg widget-bg-shadow compRiskDistribution" style={{ minHeight: "300px" }}>
                  <button className="graphRefBtn">
                    <img src={graphrefIcon} alt="" />
                  </button>
                  <div className="clearfix">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div className="comprenhHd">Comprehensive Risk Distribution</div>
                      <IconButton aria-label="close" className={classes.closeButton} style={{ cursor: "pointer" }} onClick={() => {
                        setOpenModal("Comprehensive")
                        handleOpen()
                      }}>
                        <FullscreenIcon />
                      </IconButton>
                    </div>
                    <span className="db-subtitle">
                      Histogram of Composite Scores
                    </span>
                    <CompHistogram histoSeries={compSmallHistogram.series} histoOptions={compSmallHistogram.options} />
                  </div>
                </div>
              </Grid>
               {/* Diagnostic Risk Distribution component */}
               <Grid item lg={6} sm={12} xs={12} md={12}>
                <div className="GraphBg widget-bg-shadow diagRiskDistribution">
                  <button className="graphRefBtn">
                    <img src={graphrefIcon} alt="" />
                  </button>
                  <div className="comparenhOuter">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div className="comprenhHd">Diagnostic Risk Distribution</div>
                      <IconButton aria-label="close" className={classes.closeButton} style={{ cursor: "pointer" }} onClick={() => {
                        handleOpen()
                      }}>
                        <FullscreenIcon />
                      </IconButton>
                    </div>
                    <span className="db-subtitle">
                      Histogram of Diagnostic Risk Scores
                    </span>
                    <DiagHistogram histoSeries={diagSmallHistogram.series} histoOptions={diagSmallHistogram.options} />
                  </div>
                </div>
              </Grid>
              {/* Comprehensive Risk Progression component */}
              <Grid item lg={6} sm={12} xs={12} md={12}>
                <div className="GraphBg widget-bg-shadow compRiskProgression" style={{ minHeight: "300px" }}>
                  <button className="graphRefBtn">
                    <img src={graphrefIcon} alt="" />
                  </button>
                  <div className="comparenhOuter">
                    <div className="comprenhHd">
                      Comprehensive Risk Progression
                    </div>
                    <span className="db-subtitle">
                      Median and Stratification Over 12 Months
                    </span>
                    <div className="RiskGraph barchartBx">
                      <BarChart type="comp" series={seriesComp} options={optionsComp} selected_date={patientSetInfo?.selectable_dates} reportIframe={reportIframe} />
                    </div>
                  </div>
                </div>
              </Grid>
              {/* Impactability Progression component */}
              {!['10a7319f-b662-4c79-85fd-7fc5c9cc1461'].includes(org_guid) && <Grid item lg={6} sm={12} xs={12} md={12}>
                <div className="GraphBg widget-bg-shadow impProgression" style={{ minHeight: "300px" }}>
                  <button className="graphRefBtn">
                    <img src={graphrefIcon} alt="" />
                  </button>
                  <div className="comparenhOuter">
                    <div className="comprenhHd">
                      Impactability Progression
                    </div>
                    <span className="db-subtitle">
                      Median and Stratification Over 12 Months
                    </span>
                    {!seriesImp.length ? (
                      <Alert icon={false} severity="warning" className="my-4 text-center">
                        Impactability data for this {patientSetInfo.cohort_name} are not available yet. Please check back later.
                      </Alert>
                    ) : (<div className="RiskGraph barchartBx">
                      <BarChart type="imp" series={seriesImp} options={optionsImp} selected_date={patientSetInfo?.selectable_dates} reportIframe={reportIframe} />
                    </div>
                    )}

                  </div>
                </div>
              </Grid>}
              {/* Impactability Stratification component */}
              {!['10a7319f-b662-4c79-85fd-7fc5c9cc1461'].includes(org_guid) && <Grid item lg={6} sm={12} xs={12} md={12}>
                <div className="GraphBg widget-bg-shadow impStratification">
                  <button className="graphRefBtn">
                    <img src={graphrefIcon} alt="" />
                  </button>
                  <div className="comparenhOuter">
                    <div className="comprenhHd">Impactability Stratification</div>
                    <span className="db-subtitle">Holistic assessment of the predicted impact of services. Larger means more impactable.</span>
                    <div className="comprenshGraph graph-wrapper">
                      <div className="clearfix">
                        {!pyramidImp?.length ? (
                          <Alert
                            icon={false}
                            severity="warning"
                            className="my-4 text-center"
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            No claims data available.
                          </Alert>
                        ) : (
                          <>
                            <div className="comprenshGraph graph-wrapper">
                              <div className="clearfix">
                                <div className="pyramidLeft" style={{ width: "35%" }}>
                                  <h3 className="pl-0">
                                    <small 
                                        style={{ color: "#3aa290", textDecoration: "underline", cursor: "pointer" }} 
                                        onClick={reportIframe ? null: () =>redirectToPatientList()}
                                    >
                                      View all {format(patients.active_count)} {toPluralize(toLowerCase(patientSetInfo.patient_name))}
                                    </small>
                                  </h3>
                                  <h5>
                                    or click pyramid for <br /> category details.
                                  </h5>
                                  <h5>
                                    Additionally there are <br />
                                    <small onClick={reportIframe ? null: () => redirectToPatientListUnknown("imp")}>
                                     {unknownCountImp} {toPluralize(toLowerCase(patientSetInfo.patient_name))} with unknown impactability.<br />
                                    </small>
                                  </h5>
                                </div>
                                <PyramidChart 
                                    type="overview" 
                                    pyramidValues={pyramidImp} 
                                    riskKeyName="imp" 
                                    reportIframe={reportIframe}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>}
               {/* Quality Stratification component */}
               {pyramidQual ? <Grid item lg={6} sm={12} xs={12} md={12}>
                <div className="GraphBg widget-bg-shadow impStratification">
                  <button className="graphRefBtn">
                    <img src={graphrefIcon} alt="" />
                  </button>
                  <div className="comparenhOuter">
                    <div className="comprenhHd">Quality Stratification</div>
                    <span className="db-subtitle">Quality Stratification.</span>
                    <div className="comprenshGraph graph-wrapper">
                      <div className="clearfix">
                        {(
                          <>
                            <div className="comprenshGraph graph-wrapper">
                              <div className="clearfix">
                                <div className="pyramidLeft" style={{ width: "35%" }}>
                                  <h3 className="pl-0">
                                    <small 
                                        style={{ color: "#3aa290", textDecoration: "underline", cursor: "pointer" }} 
                                        onClick={reportIframe ? null: () =>redirectToPatientList()}
                                    >
                                      View all {format(patients.active_count)} {toPluralize(toLowerCase(patientSetInfo.patient_name))}
                                    </small>
                                  </h3>
                                  <h5>
                                    or click pyramid for <br /> category details.
                                  </h5>
                                  <h5>
                                    Additionally there are <br />
                                    <small onClick={reportIframe ? null: () => redirectToPatientListUnknown("qual")}>
                                     {unknownCountQual} {toPluralize(toLowerCase(patientSetInfo.patient_name))} with unknown quality.<br />
                                    </small>
                                  </h5>
                                </div>
                                <PyramidChart 
                                    type="overview" 
                                    pyramidValues={pyramidQual} 
                                    riskKeyName="qual" 
                                    reportIframe={reportIframe}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Grid> : null}
               {/* Cohort Claims Costs component */}
               <Grid item lg={6} sm={12} xs={12} md={12}>
                <div className="GraphBg widget-bg-shadow cohortClaimsCosts" style={{ minHeight: "300px" }}>
                  <button className="graphRefBtn">
                    <img src={graphrefIcon} alt="" />
                  </button>
                  <div className="comparenhOuter">
                    <div className="comprenhHd">
                    {toCapitalize(patientSetInfo.cohort_name)} Claims Costs
                    </div>
                    <span className="db-subtitle">
                      Average Claims Cost Per Member
                    </span>
                    {!pmpmData?.length ? (
                      <Alert
                        icon={false}
                        severity="warning"
                        className="my-4 text-center"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        No claims data available.
                      </Alert>
                    ) : (
                      <div className="RiskGraph barchartBx">
                        <LineChart
                          series={Object.keys(pmpmData).length > 0 ? pmpmData : []}
                          totalCost={totalPmpmCost}
                        />
                      </div>
                    )}
                  </div>
                </div>
                </Grid>
              </>)}
              {/* Only show custom attribute chart to VHC and WCCHC organization */}
              {['50cc73a6-23c1-49ba-8276-eaf6589c789f', '2c6d7563-a160-11eb-848f-02a70ebebca3', 'a2ce0370-790f-489a-ad62-025b470bbc36'].includes(org_guid) ?
                (customAttributeChartLoader ? (
                    new Array(2).fill(0).map((_, index) => <GridSkeletonLoader key={index} />)
                  ) : (
                    customAttrChartData?.customAttribute?.length &&
                      customAttrChartData?.customAttribute.filter(attribute => {
                        return customAttrChartData?.featureFlags.find(({ custom_attr_id, temporal_progression }) => {
                          return custom_attr_id === attribute.custom_attr_id && temporal_progression
                        })
                      }).map(({ custom_attribute }) => (
                        <Grid item lg={6} sm={12} xs={12} md={12}>
                          <div
                            className="GraphBg widget-bg-shadow cohortClaimsCosts"
                            style={{ minHeight: "300px" }}
                          >
                            <button className="graphRefBtn">
                              <img src={graphrefIcon} alt="" />
                            </button>
                              <div className="comparenhOuter">
                                <div  className="comprenhHd">{`${custom_attribute} Progression `}  
                                  <Tooltip
                                    title={`The sum of counts in a particular month can exceed the total number of ${toPluralize(patientSetInfo?.patient_name)} if multiple attribute values have been recorded for the same ${patientSetInfo?.patient_name} in a single month.`}
                                    placement="top"
                                    arrow
                                  >
                                    <InfoOutlinedIcon style={{ fontSize: "18px", cursor: "pointer" }}/>
                                  </Tooltip>
                                </div>  
                                <CustomAttributeChart
                                  data={customAttrChartData?.chartData[custom_attribute]}
                                  attr_values={
                                    customAttrChartData?.attributePossibleValues?.[custom_attribute]
                                  }
                                  initialSelect={
                                    customAttrChartData?.attributePossibleValues?.[
                                      custom_attribute
                                    ]?.[0].attr_value
                                  }
                                  label={customAttrChartData?.chartLabels}
                                />
                            </div>
                          </div>
                        </Grid>
                      ))
                    )
                  ) : null
              }
            </Grid>
          </div>
          <Modal
            open={open}
            onClose={() => {
              setOpenModal("");
              handleClose();
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.customModal}
          >
            <div className={classes.modalContent}>
              <div className={classes.paper}>
              <IconButton aria-label="close" className={classes.closeButton}
                  onClick={() => {
                    setOpenModal("");
                    handleClose();
                  }}>
                  <FullscreenExitIcon />
                </IconButton>
                {
                  openModal === "Comprehensive" ?
                    <CompHistogram histoSeries={compLargeHistogram.series} histoOptions={compLargeHistogram.options} /> :
                    <DiagHistogram histoSeries={diagLargeHistogram.series} histoOptions={diagLargeHistogram.options} />
                }
              </div>
            </div>
          </Modal>
        </div>
      </section>
    </Sidebar>
  );
};

export default DashboardOverview;
