import React, { useEffect, useRef, useState } from 'react';
import "../_Dashboard.scss";

const CanvasPieAndStackChart = ({ comprehensiveData: _comprehensiveData, qualityData, impactabilityData, handlePatientListRedirect }) => {
    const canvasRef = useRef(null);
    const [selectedSlice, setSelectedSlice] = useState(_comprehensiveData[0]?.key);
    const [comprehensiveData, setComprehensiveData] = useState(_comprehensiveData);

    const { user } = JSON.parse(localStorage.getItem("user"));
    const isCareSouthUser = user?.org_guid === "10a7319f-b662-4c79-85fd-7fc5c9cc1461";

    // Redraw when the window is resized
    const handleResize = () => {
        const canvas = canvasRef.current;
        const { width, height } = canvas.parentNode.getBoundingClientRect();
        canvas.width = width;
        canvas.height = height;
        renderCanvasChart();
    };

    const drawPieSlice = (ctx, centerX, centerY, radius, startAngle, endAngle) => {
        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.arc(centerX, centerY, radius, startAngle, endAngle);
        ctx.closePath();
    };

    const drawPieLabel = (ctx, centerX, centerY, radius, startAngle, endAngle, label, percentage) => {
        if (percentage === 100) {
            // If the slice covers 100%, place the label in the center
            ctx.fillStyle = '#0066CB';
            ctx.font = '12px Arial';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(label, centerX, centerY); // Draw the label at the center
            return; // Exit the function since no need to calculate label position for a full circle
        }

        const midAngle = (startAngle + endAngle) / 2;
        const labelRadius = radius * 1.2; // Adjust label radius to be inside the pie
    
        // Calculate label position using start and end angles
        const labelX = centerX + (labelRadius * Math.cos(midAngle));
        const labelY = centerY + (labelRadius * Math.sin(midAngle));

        let rotateAngle = midAngle;
        if(!isCareSouthUser) {
            // Calculate the angle of rotation for the label            
            if (midAngle > (Math.PI / 2 + Math.PI * 2) && midAngle < (Math.PI * 1.5 + Math.PI * 2)) {
                rotateAngle += Math.PI; // Adjust the rotation angle for labels in the left half of the pie
            }

            ctx.translate(labelX, labelY); // Move the origin to the label position
            ctx.rotate(rotateAngle); // Rotate the context
        }
    
        // Ensure text is always horizontal, don't rotate it based on angle
        ctx.fillStyle = '#0066CB';
        ctx.font = '12px Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        if(isCareSouthUser) {
            ctx.fillText(label, labelX, labelY); // Draw the label
        } else {
            ctx.fillText(label, 0, 0);
            ctx.rotate(-rotateAngle); // Restore the context rotation
            ctx.translate(-labelX, -labelY); // Restore the origin position
        }        
    };
    

    const drawPieChart = (ctx) => {
        const centerX = canvasRef.current.width / (isCareSouthUser ? 2 : 4);
        const centerY = canvasRef.current.height / 2;
        const radius = Math.min(canvasRef.current.width, canvasRef.current.height) / 3;
        let startAngle = 0;

        const dataToUse = isCareSouthUser ? qualityData : comprehensiveData;

        for (let i = 0; i < dataToUse.length; i++) {
            const sliceAngle = (2 * Math.PI * dataToUse[i]["percentage"]) / 100;
            startAngle = i === 0 ? 2 * Math.PI - sliceAngle / 2 : startAngle;
            const endAngle = startAngle + sliceAngle;
            let sliceColor = dataToUse[i]["color"];
            let _centerX = centerX;
            if (selectedSlice === dataToUse[i]["key"]) {
                _centerX = _centerX + 5;
            } else {
                sliceColor = dataToUse[i]["color"]
            }
            ctx.fillStyle = sliceColor;
            drawPieSlice(ctx, _centerX, centerY, radius, startAngle, endAngle);
            ctx.fill();
            if (dataToUse[i]["percentage"] > 0) {
                drawPieLabel(ctx, _centerX, centerY, radius - 60, startAngle, endAngle, dataToUse[i]["title"], dataToUse[i]["percentage"]);
            }
            startAngle = endAngle;
        }
    }

    const drawStackLabel = (ctx, startX, boxY, boxWidth, boxHeightScaled, label) => {
        const labelX = startX + boxWidth / 2; // Center label horizontally
        const labelY = boxY + boxHeightScaled / 2; // Center label vertically

        ctx.fillStyle = '#0066CB';
        ctx.font = '12px Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(label, labelX, labelY);
    }

    const drawStackBarChart = (ctx) => {
        const boxWidth = 120;
        const boxHeight = Math.min(canvasRef.current.width, canvasRef.current.height) / 1.7;
        const boxMinHeight = 20;
        const startX = canvasRef.current.width / 1.3;
        let startY = canvasRef.current.height / 6;

        for (let i = 0; i < impactabilityData.length; i++) {
            const boxY = startY;
            const boxHeightScaled = (boxHeight * impactabilityData[i]["percentage"]) / 100;
            const _boxHeightScaled = boxHeightScaled >= boxMinHeight ? boxHeightScaled : boxMinHeight;
            const boxColor = impactabilityData[i]["color"];
            ctx.fillStyle = boxColor;
            ctx.fillRect(startX, boxY, boxWidth, _boxHeightScaled);
            drawStackLabel(ctx, startX, boxY, boxWidth, _boxHeightScaled, impactabilityData[i]["title"]);
            startY += _boxHeightScaled;
        }
    }

    // Draw connecting lines
    const drawLine = (ctx, x1, y1, x2, y2) => {
        ctx.strokeStyle = '#0066CB';
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.stroke();
    }

    const renderCanvasChart = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw pie chart
        drawPieChart(ctx);
        if (!isCareSouthUser) {
            // Draw Stack Bar
            drawStackBarChart(ctx);
            // Draw connecting lines
            const { x, y, x1, y1 } = calculatePiePointsForLine();
            const startX = canvasRef.current.width / 1.3;
            const startY = canvasRef.current.height / 6;
            const stackY1 = calculateStackPointForLine();
            drawLine(ctx, x, y, startX, startY);
            drawLine(ctx, x1, y1, startX, stackY1);
        }
    }

    const handlePieMouseHover = (ctx, x, y) => {

        const centerX = canvasRef.current.width / (isCareSouthUser ? 2 : 4);
        const centerY = canvasRef.current.height / 2;
        const radius = Math.min(canvasRef.current.width, canvasRef.current.height) / 3;

        const dataToUse = isCareSouthUser ? qualityData : comprehensiveData;

        // Check if the mouse pointer is inside any slice
        let startAngle = 0;
        for (let i = 0; i < dataToUse.length; i++) {
            const sliceAngle = (2 * Math.PI * dataToUse[i]["percentage"]) / 100;
            startAngle = i === 0 ? 2 * Math.PI - sliceAngle / 2 : startAngle;
            const endAngle = startAngle + sliceAngle;
            drawPieSlice(ctx, centerX, centerY, radius, startAngle, endAngle);

            const tooltipText = `${dataToUse[i]["title"]} : ${dataToUse[i]["percentage"]}%`;
            if (ctx.isPointInPath(x, y)) {
                // Display tooltip
                const tooltipWidth = 150; // Fixed width for the tooltip box
                const tooltipHeight = 30;
                const tooltipX = x - tooltipWidth / 2;
                const tooltipY = y - tooltipHeight - 15;
                ctx.fillStyle = dataToUse[i]["toolTipColor"];
                ctx.fillRect(tooltipX, tooltipY, tooltipWidth, tooltipHeight);
                ctx.fillStyle = '#fff';
                ctx.font = '12px Arial';
                ctx.textAlign = 'center';
                ctx.fillText(tooltipText, tooltipX + tooltipWidth / 2, tooltipY + tooltipHeight / 2);
                break;
            }

            startAngle = endAngle;
        }
    };

    const handleStackMouseHover = (ctx, x, y) => {

        const boxWidth = 120;
        const boxHeight = Math.min(canvasRef.current.width, canvasRef.current.height) / 1.7;
        const boxMinHeight = 20;
        const startX = canvasRef.current.width / 1.3;
        let startY = canvasRef.current.height / 6;

        for (let i = 0; i < impactabilityData.length; i++) {
            const boxY = startY;
            const boxHeightScaled = (boxHeight * impactabilityData[i]["percentage"]) / 100;
            const _boxHeightScaled = boxHeightScaled >= boxMinHeight ? boxHeightScaled : boxMinHeight;
            ctx.rect(startX, boxY, boxWidth, _boxHeightScaled);

            const tooltipText = `${impactabilityData[i]["title"]} : ${impactabilityData[i]["percentage"]}%`;
            if (ctx.isPointInPath(x, y)) {
                // Display tooltip
                const tooltipWidth = 120; // Fixed width for the tooltip box
                const tooltipHeight = 30;
                const tooltipX = x - tooltipWidth / 2;
                const tooltipY = y - tooltipHeight - 15;
                ctx.fillStyle = impactabilityData[i]["toolTipColor"];
                ctx.fillRect(tooltipX, tooltipY, tooltipWidth, tooltipHeight);
                ctx.fillStyle = '#fff';
                ctx.font = '12px Arial';
                ctx.textAlign = 'center';
                ctx.fillText(tooltipText, tooltipX + tooltipWidth / 2, tooltipY + tooltipHeight / 2);
                break;
            }

            startY += _boxHeightScaled;
        }
    };

    const handlePieSliceClick = (ctx, x, y) => {

        const centerX = canvasRef.current.width / (isCareSouthUser ? 2 : 4);
        const centerY = canvasRef.current.height / 2;
        const radius = Math.min(canvasRef.current.width, canvasRef.current.height) / 3;

        const dataToUse = isCareSouthUser ? qualityData : comprehensiveData;

        // Check if the mouse pointer is inside any slice
        let startAngle = 0;
        for (let i = 0; i < dataToUse.length; i++) {
            const sliceAngle = (2 * Math.PI * dataToUse[i]["percentage"]) / 100;
            startAngle = i === 0 ? 2 * Math.PI - sliceAngle / 2 : startAngle;
            const endAngle = startAngle + sliceAngle;
            drawPieSlice(ctx, centerX, centerY, radius, startAngle, endAngle);
            if (ctx.isPointInPath(x, y)) {
                setSelectedSlice(dataToUse[i]["key"]);
                if (!isCareSouthUser) {
                    const _rotatedArray = rotateArrayByKey(_comprehensiveData, dataToUse[i]["key"]);
                    setComprehensiveData(_rotatedArray.slice());
                } else {
                    handlePatientListRedirect(dataToUse[i]["key"]);
                }
                break;
            }
            startAngle = endAngle;
        }
    };

    const handleStackBoxClick = (ctx, x, y) => {

        const boxWidth = 120;
        const boxHeight = Math.min(canvasRef.current.width, canvasRef.current.height) / 1.7;
        const boxMinHeight = 20;
        const startX = canvasRef.current.width / 1.3;
        let startY = canvasRef.current.height / 6;

        for (let i = 0; i < impactabilityData.length; i++) {
            const boxY = startY;
            const boxHeightScaled = (boxHeight * impactabilityData[i]["percentage"]) / 100;
            const _boxHeightScaled = boxHeightScaled >= boxMinHeight ? boxHeightScaled : boxMinHeight;
            ctx.rect(startX, boxY, boxWidth, _boxHeightScaled);

            if (ctx.isPointInPath(x, y)) {
                handlePatientListRedirect(selectedSlice, impactabilityData[i]["key"]);
                break;
            }
            startY += _boxHeightScaled;
        }
    };

    function rotateArrayByKey(arr, key) {
        let index = arr.findIndex(obj => obj.key === key); // Find index of object with the specified key value
        if (index === -1) {
            return arr; // If key is not found, return original array
        }
        return arr.slice(index).concat(arr.slice(0, index));
    }

    const calculateCircumferencePoint = (centerX, centerY, radius, angleInRadians) => {
        const x = centerX + radius * Math.cos(angleInRadians);
        const y = centerY + radius * Math.sin(angleInRadians);
        return { x, y };
    }

    const calculatePiePointsForLine = () => {
        const centerX = canvasRef.current.width / 4;
        const centerY = canvasRef.current.height / 2;
        const radius = Math.min(canvasRef.current.width, canvasRef.current.height) / 3;
        let startAngle = 0;

        for (let i = 0; i < comprehensiveData.length; i++) {
            const sliceAngle = (2 * Math.PI * comprehensiveData[i]["percentage"]) / 100;
            startAngle = i === 0 ? 2 * Math.PI - sliceAngle / 2 : startAngle;
            const endAngle = startAngle + sliceAngle;
            let _centerX = centerX;
            if (selectedSlice === comprehensiveData[i]["key"]) {
                _centerX = _centerX + 5;
                const { x, y } = calculateCircumferencePoint(_centerX, centerY, radius, startAngle);
                const { x: x1, y: y1 } = calculateCircumferencePoint(_centerX, centerY, radius, endAngle);
                return { x, y, x1, y1 };
            }
            startAngle = endAngle;
        }
        return { x: 0, y: 0, x1: 0, y1: 0 }
    }

    const calculateStackPointForLine = () => {
        const boxHeight = Math.min(canvasRef.current.width, canvasRef.current.height) / 1.7;
        const boxMinHeight = 20;
        let startY = canvasRef.current.height / 6;

        for (let i = 0; i < impactabilityData.length; i++) {
            const boxHeightScaled = (boxHeight * impactabilityData[i]["percentage"]) / 100;
            const _boxHeightScaled = boxHeightScaled >= boxMinHeight ? boxHeightScaled : boxMinHeight;
            if (i === impactabilityData.length - 1) {
                return (startY + _boxHeightScaled);
            }
            startY += _boxHeightScaled;
        }
    }

    const handleMouseHover = (event) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        renderCanvasChart();

        const rect = canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        !isCareSouthUser && handleStackMouseHover(ctx, x, y);
        handlePieMouseHover(ctx, x, y);

    }

    const handleClick = (event) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        renderCanvasChart();

        const rect = canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        !isCareSouthUser && handleStackBoxClick(ctx, x, y);
        handlePieSliceClick(ctx, x, y);
    }

    useEffect(() => {
        if (!isCareSouthUser && comprehensiveData.length) {
            renderCanvasChart();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSlice, comprehensiveData])

    useEffect(() => {
        if (isCareSouthUser ? qualityData.length > 0 : (_comprehensiveData.length > 0 && impactabilityData.length > 0)) {
            // Initial draw and add resize event listener
            handleResize();
            window.addEventListener('resize', handleResize);
        }

        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
      <div style={{ height: "300px" }}>
        {(isCareSouthUser ? qualityData.length > 0 : (_comprehensiveData.length > 0 && impactabilityData.length > 0)) ? (
          <>
            <canvas
              ref={canvasRef}
              className="canvasPieChart"
              onClick={handleClick}
              onMouseMove={handleMouseHover}
            ></canvas>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  width: isCareSouthUser ? "100%" : "50%",
                  justifyContent: "center",
                }}
              >
                <span style={{ fontWeight: 600 }}>{isCareSouthUser ? "Quality Risk" : "Comprehensive Risk"}</span>
              </div>
              {!isCareSouthUser && <div
                style={{
                  display: "flex",
                  width: "50%",
                  justifyContent: "center",
                }}
              >
                <span style={{ paddingLeft: "150px", fontWeight: 600 }}>Impactability</span>
              </div>}
            </div>
          </>
        ) : (
          <div
            className="canvasPieChart"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
            }}
          >
            <p>No Data Available</p>
          </div>
        )}
      </div>
    );
}

export default CanvasPieAndStackChart;